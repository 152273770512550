import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Header() {

    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <header className={`main_header ${isSticky ? `sticky` : ``}`}>
            <Navbar expand="lg">
                <Container>
                    <Navbar.Brand href="#home">
                        <img src={require('../assets/image/logo.png')} className='logo' alt="JNT TechnoSoft" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="main-menu" />
                    <Navbar.Collapse id="main-menu">
                        <Nav className="ms-auto gap-3">
                            <Link to="#home" onClick={() => scrollToSection('home')}>Home</Link>
                            <Link to="#about" onClick={() => scrollToSection('about')}>About Us</Link>
                            <Link to="#product" onClick={() => scrollToSection('product')}>Product</Link>
                            <Link to="#service" onClick={() => scrollToSection('service')}>Service</Link>
                            <Link to="#client" onClick={() => scrollToSection('client')}>Client</Link>
                            <Link to="#contact" onClick={() => scrollToSection('contact')}>Contact</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}
