import React from "react";
import Header from "./header";
import Footer from "./footer";


function MainLayout({ children }) {
  return (
    <>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </>
  );
}

export default MainLayout;
