import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Home from "./Pages/Home";
import Header from './Components/header';
import Footer from './Components/footer';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/header",
    element: <Header />,
  },
  {
    path: "/footer",
    element: <Footer />,
  },

]);

export default function App() {
  return <RouterProvider router={router} />;
}
