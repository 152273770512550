import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MainLayout from "../Components/MainLayout";
import { Button, Carousel, Col, Container, Form, Row } from "react-bootstrap";
import {
  FaBalanceScaleLeft,
  FaBusinessTime,
  FaCoins,
  FaCrosshairs,
  FaEnvelope,
  FaMapMarkerAlt,
  FaMousePointer,
  FaPhoneAlt,
  FaRegWindowClose,
  FaUniversalAccess,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Service from "../assets/image/service.jpeg";
import about from "../assets/image/about_pic.jpg";
import banner1 from "../assets/image/banner1.jpeg";
import banner2 from "../assets/image/banner2.jpeg";
import banner3 from "../assets/image/banner3.jpeg";

const PRODUCT_IMG = [
  { path: require("../assets/image/product-clients/1.png") },
  { path: require("../assets/image/product-clients/2.png") },
  { path: require("../assets/image/product-clients/3.png") },
  { path: require("../assets/image/product-clients/4.png") },
  { path: require("../assets/image/product-clients/5.png") },
  { path: require("../assets/image/product-clients/6.png") },
  { path: require("../assets/image/product-clients/7.png") },
  { path: require("../assets/image/product-clients/8.png") },
  { path: require("../assets/image/product-clients/9.png") },
  { path: require("../assets/image/product-clients/10.png") },
  { path: require("../assets/image/product-clients/11.png") },
  { path: require("../assets/image/product-clients/12.png") },
  { path: require("../assets/image/product-clients/13.png") },
  { path: require("../assets/image/product-clients/14.png") },
  { path: require("../assets/image/product-clients/15.png") },
  { path: require("../assets/image/product-clients/16.png") },
  { path: require("../assets/image/product-clients/17.png") },
  { path: require("../assets/image/product-clients/18.png") },
];

const options = {
  margin: 30,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 2500,
  // navText: ["<", ">"],
  smartSpeed: 1000,
  loop: true,
  dots: false,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    600: {
      items: 4,
    },
    700: {
      items: 6,
    },
    1000: {
      items: 6,
    },
  },
};

export default function Home() {
  const bounceAnimation = {
    animation: "bounce 1s infinite",
    "@keyframes bounce": {
      "0%, 20%, 50%, 80%, 100%": {
        transform: "translateY(0)",
      },
      "40%": {
        transform: "translateY(-10px)",
      },
      "60%": {
        transform: "translateY(-5px)",
      },
    },
  };
  return (
    <MainLayout>
      {/* <CustomCursor /> */}

      <section className="p-0 homebanner border-bottom" id="home">
        {/* <img src={home} alt="banner" className="w-100" /> */}

        <Carousel autoplay indicators={false}>
          <Carousel.Item interval={1500}>
            <img src={banner1} alt="banner" className="w-100" />
            <Carousel.Caption>
              <h2>Design</h2>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img src={banner2} alt="banner" className="w-100" />
            <Carousel.Caption>
              <h2>Build</h2>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={1500}>
            <img src={banner3} alt="banner" className="w-100" />
            <Carousel.Caption>
              <h2>Maintain</h2>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>

      <section id="about">
        <Container>
          <Row>
            <Col md={6}>
              <div className="mt-5">
                <h2>About Us</h2>
                <p className="text-align-center">
                  JNT has been working on a philosophy of design, build and
                  maintenance in the areas of :
                </p>
                <ul className="about_list">
                  <li>wire and wireless networks</li>
                  <li>optical fiber</li>
                  <li>ipcctv</li>
                  <li>ipbax</li>
                  <li> access control and biometric</li>
                  <li>public address system</li>
                  <li>power backup</li>
                  <li>electrical work</li>
                  <li>web-based customized software</li>
                </ul>
                <p>
                  We have provided different types of solutions as per the
                  client's needs.
                </p>
              </div>
            </Col>
            <Col md={6} className="mt-5">
              <img
                src={about}
                alt="About Us"
                className="w-100 border rounded-3"
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="product" className="bg-light">
        <Container>
          <Row>
            <Col md={12} className="text-center">
              <h2>Product</h2>
              <p>
                JNT is working with major reputed brands as per client
                requirement like:{" "}
              </p>
            </Col>
            <Col md={12}>
              <OwlCarousel {...options} className="owl-theme mt-5">
                {PRODUCT_IMG.map((item, index) => {
                  return (
                    <div className="item border" key={index}>
                      <img src={item.path} alt="" className="w-100" />
                    </div>
                  );
                })}
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="service">
        <Container>
          <Row className="align-items-center">
            <Col md={12}>
              <h2>Service</h2>
            </Col>
            <Col md={6}>
              <ul className="list_service">
                <li>
                  <span className="label">1</span>
                  <span>
                    Design-Configuration-Deployment-Maintenance of the IT
                    infrastructure
                  </span>
                </li>
                <li>
                  <span className="label">2</span>
                  <span>Fire Safety</span>
                </li>
                <li>
                  <span className="label">3</span>
                  <span>Electrical Work</span>
                </li>
                <li>
                  <span className="label">4</span>
                  <span>IT Outsourcing</span>
                </li>
              </ul>
            </Col>
            <Col md={6} className='text-end'> <img src={Service} alt='service' className='w-100 border rounded-3' /></Col>
            <Col md={12} className="mt-5">
              <Row>
                <Col md={6}>
                  <div className="d-flex align-items-start gap-3 mb-4">
                    <span className="service_icon">
                      <FaCoins />
                    </span>
                    <div className="flex1">
                      <h3>Cost Savings</h3>
                      <p>
                        Outsourcing can often be more cost-effective than hiring
                        and training in-house staff. It eliminates expenses
                        related to salaries, benefits, infrastructure, and
                        equipment.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-start gap-3 mb-4">
                    <span className="service_icon">
                      <FaUniversalAccess />
                    </span>
                    <div className="flex1">
                      <h3> Access to Expertise</h3>
                      <p>
                        Outsourcing allows organizations to tap into specialized
                        skills and knowledge that may not be available in-house.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-start gap-3 mb-4">
                    {/* <span className='service_icon'><FaCrosshairs style={bounceAnimation} /></span> */}
                    <span className="service_icon">
                      <FaCrosshairs />
                    </span>

                    <div className="flex1">
                      <h3> Focus on Core Competencies</h3>
                      <p>
                        By delegating IT tasks to external experts,
                        organizations can concentrate on their core business
                        activities, improving overall efficiency..
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-start gap-3 mb-4">
                    <span className="service_icon">
                      <FaBalanceScaleLeft />
                    </span>
                    <div className="flex1">
                      <h3>Scalability</h3>
                      <p>
                        Outsourcing provides flexibility to scale IT resources
                        up or down based on project requirements.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-start gap-3 mb-4">
                    <span className="service_icon">
                      <FaBusinessTime />
                    </span>
                    <div className="flex1">
                      <h3> Faster Time-to-Market</h3>
                      <p>
                        Outsourcing can speed up development and deployment
                        times, allowing organizations to bring products or
                        services to market more quickly..
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-start gap-3 mb-4">
                    <span className="service_icon">
                      <FaRegWindowClose />
                    </span>
                    <div className="flex1">
                      <h3> Risk Mitigation</h3>
                      <p>
                        Vendors often have experience managing and mitigating
                        IT-related risks, which can enhance the overall
                        stability and security of systems.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-start gap-3 mb-4">
                    <span className="service_icon">
                      <FaMousePointer />
                    </span>
                    <div className="flex1">
                      <h3> Single point of contact</h3>
                      <p>
                        {" "}
                        A major benefit of outsourcing is the single point of
                        contact for entire services.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="client" className="bg-light">
        <Container>
          <Row className="align-items-center justify-content-center">
            {/* <Col md={4}>
              <img src={Client} alt="Client" className="w-100 rounded-3" />
            </Col> */}
            <Col md={10}>
              <h2>Client</h2>
              <p>
                In the small journey, we have provided multiple solutions for
                multiple verticals like packaging industries, education
                institutes, garments manufacturers, auto ancillary, food
                industries, automation service providers, government
                organizations like CPWD, PWD, MCD, Delhi Police, and
                professional firms.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="contact">
        <Container>
          <Row>
            <Col md={12} className="mb-3">
              <h2>Contact us</h2>
            </Col>
            <Col md={4}>
              <ul className="contact_info border">
                <li>
                  <FaMapMarkerAlt />
                  <span>
                    {" "}
                    105, Laxman Palace, 19 Veer Savarkar Block Shakarpur,
                    Delhi-110092
                  </span>{" "}
                </li>
                <li>
                  <FaPhoneAlt />
                  <span>
                    <Link to="tel:+918826837828">+91-8826837828</Link>
                    <br />
                    <Link to="tel:+9189811252331">+91-9811252331</Link>
                  </span>
                </li>
                <li>
                  <FaEnvelope />
                  <span>
                    <Link to="mailto:info@jnttechnosoft.com">
                      info@jnttechnosoft.com
                    </Link>
                  </span>
                </li>
              </ul>
            </Col>
            <Col md={7} className="offset-md-1">
              <h3>Get in touch</h3>
              <Form className="mb-3">
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formName" className="mb-3">
                      <Form.Control type="text" placeholder="Enter your name" />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formNumber" className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter your number"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formEmail" className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formCompany" className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter your company"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formAreaOfInterest" className="mb-3">
                  <Form.Control as="select">
                    <option>-- Select area of interest --</option>
                    <option>Networking</option>
                    <option>CCTV</option>
                    <option>IPBAX</option>
                    <option>Fire Alarm</option>
                    <option>Fire Hydrant</option>
                    <option>IT Peripherals</option>
                    <option>Access Control</option>
                    <option>Electrical Work</option>
                    <option>Cloud Software</option>
                    <option>Tally</option>
                    <option>HR & Payroll</option>
                    <option>Others</option>
                  </Form.Control>
                </Form.Group>
                <Button className="submitBtn" size="sm" type="submit">
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  );
}
