import React from 'react';
import { Container } from 'react-bootstrap';

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer_wrap">
            <Container className='text-center'>
                <p className='m-0'>© {currentYear} All rights reserved</p>
            </Container>
        </footer>
    );
}
